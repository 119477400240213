<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end"></div>
          <div class="d-flex align-items-center">
            <div
              class="
                symbol symbol-60 symbol-xxl-100
                mr-5
                align-self-start align-self-xxl-center
              "
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage:
                    currentUserPersonalInfo.photo.length > 0
                      ? `url(${this.mediaUrl + profile.profile_pic})`
                      : `url(https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png)`,
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="
                  font-weight-bolder font-size-h5
                  text-dark-75 text-hover-primary
                "
                >{{ user.first_name }}</a
              >
              <div class="text-muted">{{ user.role }}</div>
              <div class="mt-2">
                <!-- <a
                  class="
                    btn btn-sm btn-primary
                    font-weight-bold
                    mr-2
                    py-2
                    px-3 px-xxl-5
                    my-1
                    d-none
                  "
                  >Chat</a
                > -->
                <a
                  href="/job-seeker/profile"
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  >Update</a
                >
              </div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{ user.email }}</a>
            </div>
            <div v-if="role !== 'Direct Employer'" class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Phone:</span>
              <span class="text-muted" v-if="user.mobile">{{
                user.mobile
              }}</span>
            </div>
            <div v-if="role === 'Direct Employer'" class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Phone:</span>
              <span class="text-muted" v-if="profile.phone_number">{{
                profile.phone_number
              }}</span>
            </div>
            <div
              v-if="role === 'Direct Employer'"
              class="d-flex align-items-center justify-content-between"
            >
            <!-- {{profile}} -->
              <span class="font-weight-bold mr-2">Company name:</span>
              <span class="text-muted" v-if="profile.company_name">{{
                profile.company_name
              }}</span>
              <span class="text-muted" v-if="!profile.company_name"
                >Please add Company name</span
              >
            </div>
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <!-- <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                href="#"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Profile Overview</span>
              </a>
            </div> -->
            <!-- <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                href="#"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Personal Information</span>
              </a>
            </div> -->
            <div v-if="role === 'Direct Employer'" class="navi-item mb-2">
              <a
                class="navi-link py-4"
                href="/recruiter/profile"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Account Information</span>
              </a>
            </div>
            <div v-if="role === 'Job Seeker'" class="navi-item mb-2">
              <a
                class="navi-link py-4"
                href="/job-seeker/profile"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Account Information</span>
              </a>
            </div>
            <div v-if="role === 'Super admin'" class="navi-item mb-2">
              <a
                class="navi-link py-4"
                href="#"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Account Information</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                href="/change-password"
                data-tab="3"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Shield-user.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Change Password</span>
                <!-- <span class="navi-label">
                  <span
                    class="
                      label label-light-danger label-rounded
                      font-weight-bold
                    "
                  ></span>
                </span> -->
              </a>
            </div>
            <!-- <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                href="#"
                data-tab="4"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Email settings</span>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs">
        <div v-if="jobSeeker" data-app>
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <div class="row">
            <div class="col-md-12">
              <!-- create form start -->
              <v-card>
                <div style="padding: 2%">
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                    class="custom-form"
                  >
                    <div class="row">
                      <div class="col-md-2">
                        <img
                          style="
                            border-radius: 8px;
                            width: 160px;
                            height: 160px;
                          "
                          :src="url"
                        />
                        <v-file-input
                          style="font-size: 8px"
                          label="Profile picture"
                          @change="Preview_image"
                          v-model="image"
                        >
                        </v-file-input>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-9">
                        <div class="row">
                          <!-- <div class="col-md-4" style="margin-top: 3%">
                            <b-form-select
                              v-model="profession"
                              :options="professionOptions"
                              size="sm"
                              class="mt-3"
                              required
                            ></b-form-select>
                          </div> -->
                          <div class="col-md-4">
                            <v-combobox
                              v-model="areaOfExpertise"
                              :items="areaOfExpertiseOptions"
                              label="Job Functions"
                              multiple
                              size="sm"
                              class="mt-3"
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>
                          <div class="col-md-4">
                            <v-combobox
                              v-model="interestedIn"
                              :items="interestedInOptions"
                              label="Job Shifts"
                              multiple
                              size="sm"
                              class="mt-3"
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>
                          <div class="col-md-4">
                            <v-combobox
                              v-model="cities"
                              :items="cityOptions"
                              label="Willing to relocate"
                              multiple
                              size="sm"
                              class="mt-3"
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4" style="margin-top: 2%">
                            <!-- <v-text-field
                        type="number"
                        v-model="current_salary"
                        label="Current salary"
                      ></v-text-field> -->
                            <v-combobox
                              v-model="current_salary"
                              :items="desiredSalaryOptions"
                              label="Current salary*"
                              size="sm"
                              class="mt-3"
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>

                          <div class="col-md-4">
                            <v-combobox
                              v-model="desired_salary"
                              :items="desiredSalaryOptions"
                              label="Desired Salary*"
                              size="sm"
                              class="mt-3"
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>

                          <div class="col-md-4">
                            <v-checkbox
                              class="checkbox_ch"
                              style="margin-top: 8%"
                              v-model="available_for_job"
                              label="Available for Job?"
                            ></v-checkbox>
                          </div>
                          <div class="col-md-4">
                            <v-text-field
                              v-model="cnic"
                              type="number"
                              label="CNIC*"
                              :rules="rules.cnic"
                              required
                              placeholder="00000-000000-00"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="2" style="padding: 2%">
                          <label style="font-weight: bold">Skills*</label>
                          <div
                            class="row"
                            :style="
                              index == 0
                                ? 'margin-top: 0%; '
                                : 'margin-top: 2%; ' +
                                  'padding-left: 1%; padding-right: 1%'
                            "
                            v-for="(item, index) in skillsArr"
                            :key="index"
                          >
                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.profession"
                                :options="professionOptions"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>

                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.skill"
                                :options="skillsOptions"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>

                            <div class="col-md-3">
                              <v-text-field
                                type="number"
                                v-model="item.experience"
                                label="Experience in years"
                              ></v-text-field>
                            </div>

                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 2%;
                                margin-left: 4%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button"
                                type="button"
                                @click="addNewRowSkill()"
                                >+</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="custom-delete-new-record-button"
                                type="button"
                                @click="deleteRowSkill(index)"
                                >-</v-btn
                              >
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div> -->

                    <!-- <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="2" style="padding: 2%">
                          <label style="font-weight: bold"
                            >Language Skills</label
                          >
                          <div
                            class="row"
                            :style="
                              index == 0
                                ? 'margin-top: 0%; '
                                : 'margin-top: 2%; ' +
                                  'padding-left: 1%; padding-right: 1%'
                            "
                            v-for="(item, index) in languageSkillsArr"
                            :key="index"
                          >
                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.language"
                                :options="languageOptions"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>

                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.level"
                                :options="levelOptions"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>

                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 2%;
                                margin-left: 4%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button"
                                type="button"
                                @click="addNewRowSkill1()"
                                >+</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="custom-delete-new-record-button"
                                type="button"
                                @click="deleteRowSkill1(index)"
                                >-</v-btn
                              >
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div> -->

                    <div class="row" style="margin-top: 3%; margin-bottom: 2%">
                      <div class="col-md-3">
                        <v-text-field
                          v-model="dob"
                          label="Date of birth*"
                          prepend-icon="mdi-calendar"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <v-text-field
                          v-model="website"
                          type="text"
                          label="Website link"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <v-text-field
                          v-model="linkedin"
                          type="text"
                          label="LinkedIn profile"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-3" style="margin-top: 1%">
                        <label class="mb-0 pb-0">Current Location</label>
                        <b-form-select
                          v-model="city"
                          :options="cityOptions"
                          size="sm"
                          class="mt-3"
                          required
                        ></b-form-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="2" style="padding: 2%">
                          <label style="font-weight: bold"
                            >Work experience</label
                          >
                          <div
                            class="row"
                            :style="
                              index == 0
                                ? 'margin-top: 0%; '
                                : 'margin-top: 2%; ' +
                                  'padding-left: 1%; padding-right: 1%'
                            "
                            v-for="(item, index) in workExperience"
                            :key="index"
                          >
                            <div class="col-md-3">
                              <v-text-field
                                v-model="item.companyName"
                                label="Company Name"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-model="item.designation"
                                label="Designation"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                v-model="item.startingDate"
                                label="Starting date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2" style="margin-left: 3%">
                              <v-text-field
                                v-model="item.endDate"
                                label="End date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 2%;
                                margin-left: 4%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button"
                                type="button"
                                @click="addNewRow()"
                                >+</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="custom-delete-new-record-button"
                                type="button"
                                @click="deleteRow(index)"
                                >-</v-btn
                              >
                            </div>
                            <div class="col-md-12">
                              <vue-editor
                                v-model="item.description"
                                outlined
                                name="input-7-4"
                                label="Job description"
                                rows="2"
                                style="min-height: 84px"
                              ></vue-editor>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div class="row" style="margin-top: 2%">
                      <div class="col-md-12">
                        <v-card elevation="2" style="padding: 2%">
                          <label style="font-weight: bold">Education</label>
                          <div
                            class="row"
                            :style="
                              index == 0
                                ? 'margin-top: 0%; '
                                : 'margin-top: 2%; ' +
                                  'padding-left: 1%; padding-right: 1%'
                            "
                            v-for="(item, index) in education"
                            :key="index"
                          >
                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.university"
                                :options="universities"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>
                            <div class="col-md-3">
                              <b-form-select
                                v-model="item.degree"
                                :options="degrees"
                                size="sm"
                                class="mt-3"
                                required
                              ></b-form-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                v-model="item.startingDate"
                                label="Starting date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2" style="margin-left: 3%">
                              <v-text-field
                                v-model="item.endDate"
                                label="End date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 2%;
                                margin-left: 4%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button"
                                type="button"
                                @click="addNewEducationRow()"
                                >+</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="custom-delete-new-record-button"
                                type="button"
                                @click="deleteEducationRow(index)"
                                >-</v-btn
                              >
                            </div>
                            <div class="col-md-12">
                              <vue-editor
                                v-model="item.details"
                                outlined
                                name="input-7-4"
                                label="Degree details"
                                rows="2"
                                style="min-height: 84px"
                              ></vue-editor>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div class="row" style="margin-top: 2%">
                      <div class="col-md-12">
                        <v-card elevation="2" style="padding: 2%">
                          <label style="font-weight: bold">Certificate</label>
                          <div
                            class="row"
                            :style="
                              index == 0
                                ? 'margin-top: 0%; '
                                : 'margin-top: 2%; ' +
                                  'padding-left: 1%; padding-right: 1%'
                            "
                            v-for="(item, index) in certificates"
                            :key="index"
                          >
                            <div class="col-md-3">
                              <v-text-field
                                v-model="item.certificateName"
                                label="Certificate name"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-model="item.awardedBy"
                                label="Awarded by"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                v-model="item.startingDate"
                                label="Starting date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2" style="margin-left: 3%">
                              <v-text-field
                                v-model="item.endDate"
                                label="End date"
                                type="date"
                              ></v-text-field>
                            </div>
                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 2%;
                                margin-left: 4%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button"
                                type="button"
                                @click="addNewCertificateRow()"
                                >+</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="custom-delete-new-record-button"
                                type="button"
                                @click="deleteCertificateRow(index)"
                                >-</v-btn
                              >
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <v-card elevation="2" style="padding: 2%">
                      <label style="font-weight: bold"
                        >Security Questions</label
                      >
                      <div
                        class="row"
                        :style="
                          index == 0
                            ? 'margin-top: 0%; '
                            : 'margin-top: 2%; ' +
                              'padding-left: 1%; padding-right: 1%'
                        "
                        v-for="(item, index) in security_questions"
                        :key="index"
                      >
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.question"
                            label="Question"
                          ></v-text-field>
                        </div>
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.answer"
                            label="Answer"
                          ></v-text-field>
                        </div>
                        <div
                          class="col-md-1"
                          style="display: flex; margin-top: 2%"
                          v-if="security_questions.length < 2"
                        >
                          <v-btn
                            class="custom-add-new-record-button v-size--small"
                            type="button"
                            @click="addNewRow1()"
                            >+</v-btn
                          >
                          <v-btn
                            v-if="index > 0"
                            class="
                              custom-delete-new-record-button
                              v-size--small
                            "
                            type="button"
                            @click="deleteRow1(index)"
                            >-</v-btn
                          >
                        </div>
                      </div>
                    </v-card>

                    <div class="row" style="margin-top: 2%">
                      <div class="col-md-12">
                        <label><b>Professional Summary</b></label>
                        <textarea
                          name="input-7-4"
                          maxlength="600"
                          v-model="about"
                          label="About"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="6"
                        ></textarea>
                        <!-- <vue-editor
                    v-model="about"
                    outlined
                    name="input-7-4"
                    label="About"
                    rows="3"
                  ></vue-editor> -->
                      </div>
                    </div>
                    <!-- <div
                class="row"
                style="
                  margin-top: 2%;
                  display: flex;
                  flex-direction: column;
                  padding-left: 1.8%;
                "
              >
                <label>Terms & Conditions</label>
                <v-checkbox
                  v-model="checkbox"
                  label="I accept the Terms & Conditions and Privacy policy"
                ></v-checkbox>
              </div> -->

                    <v-card-actions>
                      <v-btn class="custom-submit-button" type="submit"
                        ><b-spinner
                          small
                          type="grow"
                          v-if="submitting"
                        ></b-spinner>
                        <span>Submit</span>
                      </v-btn>
                      <v-btn text @click="cancel" class="custom-cancel-button"
                        >Cancel</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </div>
              </v-card>
              <!-- create form end -->
            </div>
          </div>
        </div>
        <div v-if="recruiter" data-app>
          <div class="row">
            <div class="col-md-12">
              <!-- create form start -->
              <v-card>
                <div style="padding: 2%">
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                    class="custom-form"
                  >
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          style="
                            border-radius: 8px;
                            width: 126px;
                            height: 126px;
                          "
                          :src="url"
                        />
                        <v-file-input
                          style="font-size: 8px"
                          label="Profile picture"
                          @change="Preview_image"
                          v-model="image"
                        >
                        </v-file-input>
                      </div>
                      <div class="col-md-3">
                        <img
                          style="
                            border-radius: 8px;
                            width: 126px;
                            height: 126px;
                          "
                          :src="url1"
                        />
                        <v-file-input
                          style="font-size: 8px"
                          label="Banner Image"
                          @change="Preview_image1"
                          v-model="image1"
                        >
                        </v-file-input>
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                          v-model="companyName"
                          :rules="rules.companyName"
                          label="Company Name"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                          v-model="yourDesignation"
                          :rules="rules.yourDesignation"
                          label="Contact person designation"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                          v-model="establishedIn"
                          :rules="rules.establishedIn"
                          label="Established In"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-combobox
                          v-model="industries"
                          :items="industriesOptions"
                          label="Industries"
                          multiple
                          size="sm"
                          class="mt-3"
                          chips
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                          v-model="contactPerson"
                          label="Contact Person Name"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <!-- <div
                    class="custom-control custom-radio"
                    style="display: flex; flex-direction: column"
                  >
                    <label>Recruiter Type</label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style="margin-left: 5%"
                    >
                      <div class="form-group mb-0">
                        <input
                          type="radio"
                          id="Direct Employer"
                          name="Direct Employer"
                          class="custom-control-input"
                          value="Direct Employer"
                          v-model="recruiter_type"
                        />
                        <label
                          class="custom-control-label"
                          for="Direct Employer"
                          >Direct Employer</label
                        >
                      </div>
                    </div>

                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style="margin-left: 5%"
                    >
                      <div class="form-group mb-0">
                        <input
                          type="radio"
                          id="Recruitment Agency"
                          name="Recruitment Agency"
                          class="custom-control-input"
                          value="Recruitment Agency"
                          v-model="recruiter_type"
                        />
                        <label
                          class="custom-control-label"
                          for="Recruitment Agency"
                          >Recruitment Agency
                        </label>
                      </div>
                    </div>
                  </div> -->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                          v-model="officePhone1"
                          :rules="rules.officePhone1"
                          label="Office Phone 1"
                          required
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                          v-model="officePhone2"
                          label="Office Phone 2"
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                          v-model="email"
                          :rules="rules.email"
                          label="Email Address"
                          required
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                          v-model="website"
                          label="Website"
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                          v-model="phoneNumber"
                          :rules="rules.phoneNumber"
                          label="Phone Number"
                          required
                        ></v-text-field>
                      </div>

                      <div class="col-md-4" style="margin-top: 1%">
                        <b-form-select
                          v-model="employees"
                          :options="employeesOptions"
                          :rules="rules.employees"
                          size="sm"
                          class="mt-3"
                          required
                        ></b-form-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <v-text-field
                          v-model="linkedin"
                          label="LinkedIn profile"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <v-text-field
                          v-model="facebook"
                          label="Facebook profile"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <v-text-field
                          v-model="instagram"
                          label="Instagram profile"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <v-text-field
                          v-model="twitter"
                          label="Twitter profile"
                        ></v-text-field>
                      </div>
                    </div>

                    <v-card elevation="2" style="padding: 2%">
                      <label style="font-weight: bold">Address Details</label>
                      <div
                        class="row"
                        :style="
                          index == 0
                            ? 'margin-top: 0%; '
                            : 'margin-top: 2%; ' +
                              'padding-left: 1%; padding-right: 1%'
                        "
                        v-for="(item, index) in branches"
                        :key="index"
                      >
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.branchName"
                            label="Branch Name"
                          ></v-text-field>
                        </div>
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.branchAddress"
                            label="Address"
                          ></v-text-field>
                        </div>
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.phoneNumber"
                            label="Phone Number"
                          ></v-text-field>
                        </div>
                        <div class="col-md-2">
                          <v-text-field
                            v-model="item.email"
                            label="Email"
                          ></v-text-field>
                        </div>
                        <div
                          class="col-md-1"
                          style="display: flex; margin-top: 2%"
                        >
                          <v-btn
                            class="custom-add-new-record-button v-size--small"
                            type="button"
                            @click="addNewRow()"
                            >+</v-btn
                          >
                          <v-btn
                            v-if="index > 0"
                            class="
                              custom-delete-new-record-button
                              v-size--small
                            "
                            type="button"
                            @click="deleteRow(index)"
                            >-</v-btn
                          >
                        </div>
                      </div>
                    </v-card>

                    <v-card elevation="2" style="padding: 2%">
                      <label style="font-weight: bold"
                        >Security Questions</label
                      >
                      <div
                        class="row"
                        :style="
                          index == 0
                            ? 'margin-top: 0%; '
                            : 'margin-top: 2%; ' +
                              'padding-left: 1%; padding-right: 1%'
                        "
                        v-for="(item, index) in security_questions"
                        :key="index"
                      >
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.question"
                            label="Question"
                          ></v-text-field>
                        </div>
                        <div class="col-md-3">
                          <v-text-field
                            v-model="item.answer"
                            label="Answer"
                          ></v-text-field>
                        </div>
                        <div
                          class="col-md-1"
                          style="display: flex; margin-top: 2%"
                          v-if="security_questions.length < 2"
                        >
                          <v-btn
                            class="custom-add-new-record-button v-size--small"
                            type="button"
                            @click="addNewRow1()"
                            >+</v-btn
                          >
                          <v-btn
                            v-if="index > 0"
                            class="
                              custom-delete-new-record-button
                              v-size--small
                            "
                            type="button"
                            @click="deleteRow1(index)"
                            >-</v-btn
                          >
                        </div>
                      </div>
                    </v-card>

                    <!-- <div class="row" style="margin-top: 2%"> -->
                    <!-- <div class="col-md-6">
                        <label>Mission Statement</label>
                        <vue-editor
                          v-model="missionStatement"
                          outlined
                          name="input-7-4"
                          label="Mission Statement"
                          rows="6"
                        ></vue-editor>
                      </div> -->

                    <!-- <div class="col-md-6">
                        <label>Vision Statement</label>
                        <vue-editor
                          v-model="visionStatement"
                          outlined
                          name="input-7-4"
                          label="Vision Statement"
                          rows="6"
                        ></vue-editor>
                      </div> -->
                    <!-- </div> -->

                    <div class="row" style="margin-top: 2%">
                      <div class="col-md-12">
                        <label>About Your Company</label>
                        <vue-editor
                          v-model="aboutCompany"
                          outlined
                          name="input-7-4"
                          label="About Your Company"
                          rows="8"
                        ></vue-editor>
                      </div>
                    </div>
                    <!-- <div
                class="row"
                style="
                  margin-top: 2%;
                  display: flex;
                  flex-direction: column;
                  padding-left: 1.8%;
                "
              >
                <label>Terms & Conditions</label>
                <v-checkbox
                  v-model="checkbox"
                  label="I accept the Terms & Conditions and Privacy policy"
                ></v-checkbox>
              </div> -->

                    <v-card-actions style="margin-top: 3%">
                      <v-btn
                        :disabled="submitting"
                        class="custom-submit-button"
                        type="submit"
                        ><b-spinner
                          small
                          type="grow"
                          v-if="submitting"
                        ></b-spinner>
                        <span>Submit</span>
                      </v-btn>
                      <v-btn text @click="cancel" class="custom-cancel-button"
                        >Cancel</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </div>
              </v-card>
              <!-- create form end -->
            </div>
          </div>
        </div>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import GeneralService from "@/MainServices/GeneralService.js";
import { VueEditor } from "vue2-editor";

export default {
  name: "custom-page",
  components: {
    VueEditor,
  },

  data() {
    return {
      mediaUrl: MEDIA_URL,
      tabIndex: 0,
      role: JSON.parse(localStorage.getItem("user")).role,
      user: JSON.parse(localStorage.getItem("user")),
      profile: JSON.parse(localStorage.getItem("profile")),
      jobSeeker:
        JSON.parse(localStorage.getItem("user")).role == "Job Seeker"
          ? true
          : false,
      recruiter:
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,

      // here
      url: "",
      url1: "",
      image: null,
      image1: null,
      form: [""],
      search: null,
      loading: true,
      success: false,
      user_id:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      submitting: false,
      companyName: "",
      recruiter_type: "Direct Employer",
      yourDesignation: "",
      officePhone1: "",
      officePhone2: "",
      email:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).email
          : "",
      establishedIn: "",
      website: "",
      phoneNumber: "",
      jobFunction: "",
      linkedin: "",
      facebook: "",
      instagram: "",
      twitter: "",
      cnic: "",
      employees: null,
      branches: [
        { branchName: "", branchAddress: "", email: "", phoneNumber: "" },
      ],
      security_questions: [{ question: "", answer: "" }],
      missionStatement: "",
      visionStatement: "",
      aboutCompany: "",
      checkbox: false,
      rules: {
        companyName: [
          (val) => (val || "").length > 0 || "Company name is required",
        ],
        yourDesignation: [
          (val) => (val || "").length > 0 || "Your designation is required",
        ],
        // officePhone1: [
        //   (val) => (val || "").length > 0 || "Office phone number is required",
        // ],
        email: [(val) => (val || "").length > 0 || "Email is required"],
        establishedIn: [
          (val) => (val || "").length > 0 || "Established In field required",
        ],
        phoneNumber: [
          (val) => (val || "").length > 0 || "Phone number is required",
        ],
        // jobFunction: [
        //   (val) => (val || "").length > 0 || "Job function is required",
        // ],
        employees: [
          (val) => (val || "").length > 0 || "No. of employees required",
        ],
        cnic: [
          (val) => (val || "").length < 16 || "CINC max length 14 characters",
        ],
      },
      data: [],
      jobFunctionsOptions: [],
      employeesOptions: [
        { value: null, text: "No. of Employees" },
        { value: "1-10", text: "1-10" },
        { value: "11-25", text: "11-25" },
        { value: "26-50", text: "26-50" },
        { value: "51-100", text: "51-100" },
        { value: "101-250", text: "101-250" },
        { value: "251-500", text: "251-500" },
        { value: "500-1000", text: "500-1000" },
        { value: "1000+", text: "1000+" },
      ],
      industries: null,
      industriesOptions: [],
      contactPerson: "",

      // here
      about: "",
      profession: "",
      professionOptions: [],

      areaOfExpertise: null,
      areaOfExpertiseOptions: [],

      interestedIn: null,
      interestedInOptions: [],

      dob: "",
      current_salary: "",
      available_for_job: 0,
      city: null,
      cityOptions: [],
      cities: [],
      desired_salary: null,
      desiredSalaryOptions: [
        "Upto 20,000",
        "20,001 - 40,000",
        "40,001 - 60,000",
        "60,001 - 80,000",
        "80,001 - 100,000",
        "100,001 - 125,000",
        "125,001 - 150,000",
        "150,001 - 175,000",
        "175,001 - 225,000",
        "225,001 - 275,000",
        "275,001 - 300,000",
        "300,000 - 350,000",
        "350,000 +",
      ],
      skillsArr: [
        {
          profession: "",
          skill: "",
          experience: "",
        },
      ],

      languageOptions: ["English", "Urdu"],
      levelOptions: ["Basic", "Conversational", "Native"],
      languageSkillsArr: [
        {
          language: "",
          level: "",
        },
      ],

      workExperience: [
        {
          companyName: "",
          designation: "",
          startingDate: "",
          endDate: "",
          description: "",
        },
      ],

      universities: [],
      degrees: [],
      education: [
        {
          university: null,
          degree: null,
          startingDate: "",
          endDate: "",
          details: "",
        },
      ],

      certificates: [
        { certificateName: "", awardedBy: "", startingDate: "", endDate: "" },
      ],

      skills: null,
      skillsOptions: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
    this.getInititalData();
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },

    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },

    addNewRowSkill() {
      this.skillsArr.push({
        profession: "",
        skill: "",
        experience: "",
      });
    },
    deleteRowSkill(index) {
      var temp = [];
      this.skillsArr.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.skillsArr = temp;
    },
    addNewRowSkill1() {
      this.languageSkillsArr.push({
        language: "",
        level: "",
      });
    },
    deleteRowSkill1(index) {
      var temp = [];
      this.languageSkillsArr.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.languageSkillsArr = temp;
    },
    addNewEducationRow() {
      this.education.push({
        university: null,
        degree: null,
        startingDate: "",
        endDate: "",
        score: "",
      });
    },
    deleteEducationRow(index) {
      var temp = [];
      this.education.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.education = temp;
    },
    addNewCertificateRow() {
      this.certificates.push({
        certificateName: "",
        awardedBy: "",
        startingDate: "",
        endDate: "",
      });
    },
    deleteCertificateRow(index) {
      var temp = [];
      this.certificates.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.certificates = temp;
    },

    cancel() {
      this.$router.push({ name: "dashboard" });
    },
    resetForm() {
      this.form = [""];
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },

    Preview_image1() {
      this.url1 = URL.createObjectURL(this.image1);
    },
    getInititalData() {
      if (this.jobSeeker) {
        GeneralService.getSingleJobSeekerProfileWithData(this.user_id)
          .then((res) => {
            var _res = res.data.profile;
            // fetch professions
            res.data.professions.forEach((element) => {
              this.professionOptions.push({
                value: element.profession_name,
                text: element.profession_name,
              });
            });
            this.professionOptions.unshift(
              ...[{ value: "", text: "Profession" }]
            );

            // fetch expertise
            res.data.jobFunction.forEach((element) => {
              this.areaOfExpertiseOptions.push(element.job_function_name);
            });

            if (_res.security_questions && _res.security_questions.length > 0) {
              this.security_questions = _res.security_questions;
            }

            // interested options
            res.data.jobShifts.forEach((element) => {
              this.interestedInOptions.push(element.job_shift);
            });

            // cities
            res.data.cities.forEach((element) => {
              this.cityOptions.push(element.city_name);
            });
            // this.cityOptions.unshift(
            //   ...[{ value: null, text: "current location" }]
            // );

            // fetch universities
            res.data.universities.forEach((element) => {
              this.universities.push({
                value: element.university_name,
                text: element.university_name,
              });
            });
            this.universities.unshift(...[{ value: null, text: "University" }]);

            // fetch degrees
            res.data.degreeLevel.forEach((element) => {
              this.degrees.push({
                value: element.degree_level,
                text: element.degree_level,
              });
            });
            this.degrees.unshift(...[{ value: null, text: "Degree" }]);

            // fetch skills
            res.data.skills.forEach((element) => {
              this.skillsOptions.push({
                value: element.skill_name,
                text: element.skill_name,
              });
            });
            this.skillsOptions.unshift(...[{ value: "", text: "Skill" }]);

            if (_res) {
              if (_res.profile_pic && _res.profile_pic.length > 0) {
                this.url = this.mediaUrl + _res.profile_pic;
              }
              if (_res.profession && _res.profession.length > 0) {
                this.profession = _res.profession;
              }

              this.available_for_job = _res.available_for_job;

              if (_res.details && _res.details.length > 0) {
                this.about = _res.details;
              }
              if (_res.area_of_expertise && _res.area_of_expertise.length > 0) {
                this.areaOfExpertise = _res.area_of_expertise;
              }
              if (_res.interested_in && _res.interested_in.length > 0) {
                this.interestedIn = _res.interested_in;
              }
              if (_res.dob && _res.dob.length > 0) {
                this.dob = _res.dob;
              }
              if (_res.current_salary == "null") {
                this.current_salary = "";
              } else {
                this.current_salary = _res.current_salary;
              }

              if (_res.cnic == "null") {
                this.cnic = "";
              } else {
                this.cnic = _res.cnic;
              }

              if (_res.linkedin && _res.linkedin.length > 0) {
                this.linkedin = _res.linkedin;
              }
              if (_res.website && _res.website.length > 0) {
                this.website = _res.website;
              }
              if (_res.city && _res.city.length > 0) {
                this.city = _res.city;
              }
              if (_res.desired_salary == "null") {
                this.desired_salary = "";
              } else {
                this.desired_salary = _res.desired_salary;
              }
              if (_res.current_salary == "null") {
                this.current_salary = "";
              } else {
                this.current_salary = _res.current_salary;
              }

              if (
                _res.willing_to_relocate &&
                _res.willing_to_relocate.length > 0
              ) {
                this.cities = _res.willing_to_relocate;
              }
              if (_res.skills && _res.skills.length > 0) {
                this.skillsArr = _res.skills;
              }
              if (_res.language_skills && _res.language_skills.length > 0) {
                this.languageSkillsArr = _res.language_skills;
              }
              if (_res.education && _res.education.length > 0) {
                this.education = _res.education;
              }
              if (_res.work_experience && _res.work_experience.length > 0) {
                this.workExperience = _res.work_experience;
              }
              if (_res.certificate && _res.certificate.length > 0) {
                this.certificates = _res.certificate;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${API_URL}/recruiter-profile/${this.user_id}`)
          .then((res) => {
            if (res.data.profile_pic && res.data.profile_pic.length > 0) {
              this.url = this.mediaUrl + res.data.profile_pic;
            }
            if (res.data.banner && res.data.banner.length > 0) {
              this.url1 = this.mediaUrl + res.data.banner;
            }
            this.companyName = res.data.company_name;
            this.contactPerson = res.data.contact_person;
            this.recruiter_type = res.data.recruiter_type;
            this.yourDesignation = res.data.your_designation;
            // +++++++++++++++++++++++++++++++++++++++++++++++++++
            if (res.data.office_phone_1 == "null") {
              this.officePhone1 = "";
            } else {
              this.officePhone1 = res.data.office_phone_1;
            }
            if (res.data.office_phone_2 == "null") {
              this.officePhone2 = "";
            } else {
              this.officePhone2 = res.data.office_phone_2;
            }
            if (res.data.established_in == "null") {
              this.establishedIn = "";
            } else {
              this.establishedIn = res.data.established_in;
            }
            if (res.data.linkedin == "null") {
              this.linkedin = "";
            } else {
              this.linkedin = res.data.linkedin;
            }
            if (res.data.facebook == "null") {
              this.facebook = "";
            } else {
              this.facebook = res.data.facebook;
            }
            if (res.data.instagram == "null") {
              this.instagram = "";
            } else {
              this.instagram = res.data.instagram;
            }
            if (res.data.twitter == "null") {
              this.twitter = "";
            } else {
              this.twitter = res.data.twitter;
            }
            if (res.data.mission_statement == "null") {
              this.missionStatement = "";
            } else {
              this.missionStatement = res.data.mission_statement;
            }
            if (res.data.vision_statement == "null") {
              this.visionStatement = "";
            } else {
              this.visionStatement = res.data.vision_statement;
            }
            if (res.data.about_company == "null") {
              this.aboutCompany = "";
            } else {
              this.aboutCompany = res.data.about_company;
            }
            // ====================================================
            this.website = res.data.website;
            this.phoneNumber = res.data.phone_number;
            if (res.data.job_function && res.data.job_function.length > 0) {
              this.jobFunction = res.data.job_function;
            }
            this.employees = res.data.employees;
            if (res.data.branches && res.data.branches.length > 0) {
              this.branches = res.data.branches;
            }

            if (
              res.data.security_questions &&
              res.data.security_questions.length > 0
            ) {
              this.security_questions = res.data.security_questions;
            }

            if (res.data.industries && res.data.industries.length > 0) {
              this.industries = res.data.industries;
            }
            res.data.industriesOptions.forEach((element) => {
              this.industriesOptions.push(element);
            });

            res.data.jobFunction.forEach((element) => {
              this.jobFunctionsOptions.push({
                value: element.job_function_name,
                text: element.job_function_name,
              });
            });
            // this.jobFunctionsOptions.unshift(
            //   ...[{ value: null, text: "Job function" }]
            // );
          })
          .catch(() => {
            // Swal.fire({
            //   title: "",
            //   text: "Unable to fetch profile!",
            //   icon: "error",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false,
            // });
          });
      }
    },
    addNewRow() {
      this.branches.push({
        branchName: "",
        branchAddress: "",
        email: "",
        phoneNumber: "",
      });
    },
    deleteRow(index) {
      var temp = [];
      this.branches.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.branches = temp;
    },
    addNewRow1() {
      this.security_questions.push({
        question: "",
        answer: "",
      });
    },
    deleteRow1(index) {
      var temp = [];
      this.security_questions.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.security_questions = temp;
    },
    submit() {
      if (this.jobSeeker) {
        if (this.current_salary === "" || this.current_salary === null) {
          Swal.fire({
            title: "",
            text: "Please select Current Salary*",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.desired_salary === "" || this.desired_salary === null) {
          Swal.fire({
            title: "",
            text: "Please select Desired Salary*",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.cnic === "" || this.cnic === null) {
          Swal.fire({
            title: "",
            text: "Please add your cnic*",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        // if (
        //   this.skillsArr[0].profession === "" ||
        //   this.skillsArr[0].profession === null
        // ) {
        //   Swal.fire({
        //     title: "",
        //     text: "Please select your Profession*",
        //     icon: "error",
        //     confirmButtonClass: "btn btn-secondary",
        //     heightAuto: false,
        //   });
        //   return;
        // }
        // if (
        //   this.skillsArr[0].skill === "" ||
        //   this.skillsArr[0].skill === null
        // ) {
        //   Swal.fire({
        //     title: "",
        //     text: "Please add your skill*",
        //     icon: "error",
        //     confirmButtonClass: "btn btn-secondary",
        //     heightAuto: false,
        //   });
        //   return;
        // }
        // if (
        //   this.skillsArr[0].experience === "" ||
        //   this.skillsArr[0].experience === null
        // ) {
        //   Swal.fire({
        //     title: "",
        //     text: "Please add your experience*",
        //     icon: "error",
        //     confirmButtonClass: "btn btn-secondary",
        //     heightAuto: false,
        //   });
        //   return;
        // }
        if (this.dob === "" || this.dob === null) {
          Swal.fire({
            title: "",
            text: "Please add your Date of Birth*",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }

        this.submitting = true;
        let formData = new FormData();
        formData.append("profile_pic", this.image);
        formData.append("available_for_job", this.available_for_job ? 1 : 0);
        // formData.append("profession", this.profession);
        formData.append("cnic", this.cnic);
        formData.append("details", this.about);
        formData.append("desired_salary", this.desired_salary);
        formData.append("area_of_expertise", this.areaOfExpertise);
        formData.append("interested_in", this.interestedIn);
        formData.append("dob", this.dob);
        formData.append("linkedin", this.linkedin);
        formData.append("current_salary", this.current_salary);
        formData.append("website", this.website);
        formData.append("city", this.city);
        formData.append("willing_to_relocate", this.cities);
        formData.append(
          "security_questions",
          JSON.stringify(this.security_questions)
        );

        formData.append("skills", JSON.stringify(this.skillsArr));
        formData.append(
          "language_skills",
          JSON.stringify(this.languageSkillsArr)
        );

        formData.append("education", JSON.stringify(this.education));
        formData.append("work_experience", JSON.stringify(this.workExperience));
        formData.append("certificate", JSON.stringify(this.certificates));

        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(`${API_URL}/job-seeker-profile/${this.user_id}`, formData)
          .then((res) => {
            this.loading = false;
            this.success = true;
            this.submitting = false;
            Swal.fire({
              title: "",
              text: "Profile updated",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .catch(() => {
            this.loading = false;
            this.submitting = false;
            Swal.fire({
              title: "",
              text: "Something went wrong!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      } else {
        if (this.companyName == "" || this.companyName == null) {
          Swal.fire({
            title: "",
            text: "Company name is require",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.yourDesignation == "" || this.yourDesignation == null) {
          Swal.fire({
            title: "",
            text: "Please enter your designation",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.contactPerson == "" || this.contactPerson == null) {
          Swal.fire({
            title: "",
            text: "Please enter your contact person name",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.establishedIn == "" || this.establishedIn == null) {
          Swal.fire({
            title: "",
            text: "Please enter company established year",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.employees == "" || this.employees == null) {
          Swal.fire({
            title: "",
            text: "Please select number of employees",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.industries == "" || this.industries == null) {
          Swal.fire({
            title: "",
            text: "Please select at last one industrie",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.email == "" || this.email == null) {
          Swal.fire({
            title: "",
            text: "Enter company's email address",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.phoneNumber == "" || this.phoneNumber == null) {
          Swal.fire({
            title: "",
            text: "Please enter your mobile number",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.website == "" || this.website == null) {
          Swal.fire({
            title: "",
            text: "Please add your website link",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (
          this.branches[0].branchName == "" ||
          this.branches[0].branchName == null ||
          this.branches[0].branchAddress == "" ||
          this.branches[0].branchAddress == null
        ) {
          Swal.fire({
            title: "",
            text: "Please add your first branch Deatails",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        if (this.aboutCompany == "" || this.aboutCompany == null) {
          Swal.fire({
            title: "",
            text: "Please add your about company",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          return;
        }
        this.submitting = true;
        let formData = new FormData();
        formData.append("profile_pic", this.image);
        formData.append("banner", this.image1);
        formData.append("recruiter_type", this.recruiter_type);
        formData.append("companyName", this.companyName);
        formData.append("contactPerson", this.contactPerson);
        formData.append("yourDesignation", this.yourDesignation);
        formData.append("officePhone1", this.officePhone1);
        formData.append("officePhone2", this.officePhone2);
        formData.append("email", this.email);
        formData.append("establishedIn", this.establishedIn);
        formData.append("website", this.website);
        formData.append("phoneNumber", this.phoneNumber);
        formData.append("jobFunction", this.jobFunction);
        formData.append("linkedin", this.linkedin);
        formData.append("facebook", this.facebook);
        formData.append("instagram", this.instagram);
        formData.append("twitter", this.twitter);
        formData.append("employees", this.employees);
        formData.append("branches", JSON.stringify(this.branches));
        formData.append(
          "security_questions",
          JSON.stringify(this.security_questions)
        );
        formData.append("industries", JSON.stringify(this.industries));
        formData.append("missionStatement", this.missionStatement);
        formData.append("visionStatement", this.visionStatement);
        formData.append("aboutCompany", this.aboutCompany);

        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(`${API_URL}/recruiter-profile/${this.user_id}`, formData)
          .then((res) => {
            // console.log(res);
            this.loading = false;
            this.success = true;
            this.submitting = false;
            if (res.data == "company") {
              Swal.fire({
                title:
                  "Your company is already registered. Please use your existing account or contact us if you need any support.",
                text: "",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            } else {
              Swal.fire({
                title: "",
                text: "Company profile updated",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.submitting = false;
            Swal.fire({
              title: "",
              text: "Something went wrong!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
  },
};
</script>
